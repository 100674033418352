$(document).ready(function () {
     // EVENTO CUSTOM HERO BANNER ------------------------------------>
    $("section.hero .swiper-slide a").click(function () {
        DY.API("event", {
            name: "Click Hero banner",
        });
    });


    // EVENTO CUSTOM IR A COMPRAR ------------------------------------>
    $(".contact a:first-child").click(function () {

        DY.API("event", {
            name: "Click Ir a comprar",
        });
    });

    $(".product-media .content a").click(function () {
        DY.API("event", {
            name: "Click Ir a comprar",
        });
    });

    // EVENTO CUSTOM CLICK NAVBAR ------------------------------------>
    $(".menu a").click(function () {
        var navLinkItem = $(this).text();

        DY.API("event", {
        name: "Click Navbar",
        properties: {
                title: navLinkItem.toLowerCase(),
            },
        });
    });

    // EVENTO CUSTOM CLICK PRODUCTO ---------------------------------->
    $(".productos .ls-card-product a").click(function () {
        var productoTitulo = $(this).find("h3").text();

        DY.API("event", {
            name: "User Click Product",
            properties: {
                title: productoTitulo,
            },
        });
    });

    // EVENTO CUSTOM CLICK POST -------------------------------------->
    $(".ls-card-news a").click(function () {
        DY.API("event", {
            name: "Click Post",
        });
    });

    // EVENTO CUSTOM CLICK QUESTION -------------------------------------->
    $(".ls-card-question .card-center").click(function () {
        var question = $(this).find("h3").text();

        DY.API("event", {
            name: "Click Pregunta",
            properties: {
                question: question
            }
        });
    });

    // EVENTO PRE-DEFINED FILTER ITEMS ------------------------------->
    $(".item-filter button.item").click(function () {
        var filtroTitulo = $(this).text();

        DY.API("event", {
            name: "Filter Items",
            properties: {
                dyType: "filter-items-v1",
                filterType: "Tips & Consejos",
                filterStringValue: filtroTitulo
            }
        });
    });

    // EVENTO PRE-DEFINED CHANGE ATTRIBUTE --------------------------->
    $(".product-media .product-detail #single-prod-units a").click(function () {
        var atributoTitulo = $(this).text();

        DY.API("event", {
        name: "Change Attribute",
        properties: {
            dyType: "change-attr-v1",
            attributeType: "Unidades",
            attributeValue: parseInt(atributoTitulo)
        }
        });
    });

    // EVENTO CUSTOM CALCULADORA DE PERIODO -------------------------------------->
    $(".period-calendar-container div.ls-btn").click(function () {
        var startDate = $(".calender #calender_icon p span").text();
        var periodDays = $(".last_period input.select-dropdown").val();
        var cyclesLength = $(".menstrual_cyc input.select-dropdown").val();

        DY.API("event", {
            name: "Calculadora de período",
            properties: {
                startDate: startDate,
                periodDays: periodDays,
                cyclesLength: cyclesLength
            }
        });
    });
})