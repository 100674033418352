$(document).ready(function() {


    $('.dropdown-toggle').dropdown();
    $('.mdb-select').material_select();
    new WOW().init();

    //*SVG TO CODE*//
    jQuery('img.svg').each(function() {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            var $svg = jQuery(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });


    /*******************************************************************************
     TOOLTIP
     *******************************************************************************/
    $(function() {
        $('[data-toggle="tooltip"]').tooltip()
    });


    /*******************************************************************************
     MATCH MEDIA
     *******************************************************************************/

    $(function() {
        if (matchMedia) {
            var mq = window.matchMedia("(min-width: 1025px)");
            mq.addListener(WidthChange);
            WidthChange(mq);
        }

        function WidthChange(mq) {
            if (mq.matches) {
                $(".bg_images_dm").each(function() {
                    var attr = $(this).attr('data-image-src');
                    if (typeof attr !== typeof undefined && attr !== false) {
                        $(this).css('background-image', 'url(' + attr + ')');
                    }
                });
            }
            else {
                $(".bg_images_dm").each(function() {
                    var attr = $(this).attr('data-image-src-sm');
                    if (typeof attr !== typeof undefined && attr !== false) {
                        $(this).css('background-image', 'url(' + attr + ')');
                    }
                });
            }
        }
    });

    /*******************************************************************************
     SEARCH
     *******************************************************************************/

    $(".contact .search").on("click", function(e) {
        $("#search").toggleClass("open");
    });

    $(".destacado.search .ls-btn").on("click", function(e) {
        $("#search").toggleClass("open");
    });

    $("#search .closebtn").on("click", function(e) {
        $("#search").toggleClass("open");
    });


    /*******************************************************************************
     CARD COPITA
     *******************************************************************************/
    $('.card-list .copita-card .card-center').on('click', function(e) {
        $(this).parent().parent().toggleClass("active");
        e.preventDefault();
    });

    $('.card-list .copita-card .close').on('click', function(e) {
        $(this).parent().parent().parent().toggleClass("active");
        e.preventDefault();
    });


    $(".heart").on('click', function() {
        $(this).toggleClass('anim-like');
    });


    /*******************************************************************************
     ISOTOPE NOTAS
     *******************************************************************************/

    $('.item-filter select').on('change', function() {
        buttonFilter = this.value;
        $('html,body').animate({
                scrollTop: $("#filters").offset().top - 75
            },
            'slow');
        $grid.isotope();
        load_images();
    });

    var qsRegex;
    var buttonFilter;
    var $grid = $('.grid').isotope({
        itemSelector: '.ls-card-item',
        layoutMode: 'fitRows',
        filter: function() {
            var $this = $(this);
            var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
            var buttonResult = buttonFilter ? $this.is(buttonFilter) : true;
            return searchResult && buttonResult;
        },
    });

    $grid.on('layoutComplete', function(event, laidOutItems) {
        $(window).trigger("scroll");
    });

    $('#filters').on('click', 'button', function() {
        buttonFilter = $(this).attr('data-filter');
        $grid.isotope();
    });

    $('#content-menu-filter ul li .item').on('click', function() {
        buttonFilter = $(this).attr('data-filter');
        $grid.isotope();
    });

    $('#content-menu-filter h4 a.prods').on('click', function() {
        buttonFilter = $(this).attr('data-filter');
        $grid.isotope();
    });

    var $quicksearch = $('.quicksearch').keyup(debounce(function() {
        qsRegex = new RegExp($quicksearch.val(), 'gi');
        $grid.isotope();
    }));


    $('.item-filter').each(function(i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on('click', 'button', function() {
            $buttonGroup.find('.active').removeClass('active');
            $(this).addClass('active');
        });
    });


    function debounce(fn, threshold) {
        var timeout;
        return function debounced() {
            if (timeout) {
                clearTimeout(timeout);
            }

            function delayed() {
                fn();
                timeout = null;
            }

            setTimeout(delayed, threshold || 50);
        };
    }



    var load_images = function() {
        $('.lazy').lazy({
            threshold: 0,
            visibleOnly: true,
            beforeLoad: function(element) {
                $(element).addClass('lazy-loading')
            },
            afterLoad: function(element) {
                $(element).removeClass('lazy-loading')
                $(element).addClass('animated fadeIn')
            }
        })
    }
    load_images();



    /*******************************************************************************
     MENU MOBILE
     *******************************************************************************/
    tl = new TimelineMax({
        paused: true,
        reversed: true
    });
    menu_back = "#content-menu-mobile";
    menu_content = "#content-menu-mobile li";

    tl.staggerTo(menu_back, 0.4, {
        right: '0%'
    })
    tl.staggerTo(menu_content, 0.4, {
        left: '0%'
    }, 0.1, '-=0.75');

    $('#hamburger').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass("open");
        tl.reversed() ? tl.play() : tl.reverse();
    });


    /*******************************************************************************
     Hide Header on on scroll down
     *******************************************************************************/

    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.header').outerHeight();
    var navbarHeight = 320;

    $(window).scroll(function(event) {
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 150);


    function hasScrolled() {
        var st = $(this).scrollTop();
        if (Math.abs(lastScrollTop - st) <= delta)
            return;
        if (st > lastScrollTop && st > navbarHeight) {
            $('.fixed-nav').removeClass('nav-down').addClass('nav-up');
        }
        else {
            if (st < 320) {
                $('.fixed-nav').removeClass('nav-up').addClass('nav-down');
            }
        }
        lastScrollTop = st;
    }



    /*******************************************************************************
     VIDEO
     *******************************************************************************/

    $('.video-thumb').on('click', function(e) {
        e.preventDefault();
        $(".video-thumb").removeClass('active')
        $(this).addClass("active");
        var id = $(this).attr('data-youtube-id');
        var autoplay = '?autoplay=1';
        var related_no = '&rel=0';
        var src = '//www.youtube.com/embed/' + id + autoplay + related_no;
        $("#youtube").attr('src', src);
        return false;
    });

    var swiper = new Swiper('#slider-videos', {
        nextButton: ".btn-next-videos",
        prevButton: ".btn-prev-videos",
        slidesPerView: 3,
        paginationClickable: true,
        spaceBetween: 0
    });

    var swiper = new Swiper('#slider-rec', {
        pagination: '.swiper-pagination-rec',
        paginationClickable: true,
        spaceBetween: 30,
        autoplay: 7500,
        autoplayDisableOnInteraction: false
    });


    tl3 = new TimelineMax({
            paused: true,
            reversed: true
        }),
        video_full = ".destacado.videofull",
        video_iframe = ".content.video",
        opacity = ".wrapper",
        whtml = "html",
        texto_block = ".text-block";


    tl3.to(texto_block, 0.5, {
        'opacity': '0',
        'display': 'none'
    });
    tl3.to(window, 0.5, {
        scrollTo: {
            y: "#videofull",
            autoKill: false,
            ease: Power4.easeOut
        }
    });
    tl3.to(video_full, 0.5, {
        css: {
            'height': '100vh',
            ease: Power4.easeOut
        }
    }, '-=0.5');
    tl3.to(opacity, 0.5, {
        css: {
            'background-color': 'rgba(0,0,0,.5)',
            ease: Power4.easeOut
        }
    });
    tl3.to(video_iframe, 0, {
        'opacity': '1',
        'z-index': '1'
    });
    tl3.to(whtml, 0, {
        'overflow': 'hidden',
        'paddingRight': '8px',
        'background-color': '#ff3766'
    }, '-=0.4');


    $('.swich-video').on('click', function(e) {
        e.preventDefault();
        var id = $("#youtube").attr('data-youtube-id');
        var autoplay = '?autoplay=1';
        var related_no = '&rel=0';
        var src = '//www.youtube.com/embed/' + id + autoplay + related_no;
        $("#youtube").attr('src', src);
        tl3.reversed() ? tl3.play() : tl3.reverse();
    });

    $('.close-video').on('click', function(e) {
        e.preventDefault();
        var stopVideo = function(player) {
            player.prop('src', '');
        };
        stopVideo($('#youtube'));
        tl3.reversed() ? tl3.play() : tl3.reverse();
    });

    /*******************************************************************************
     PRODUCT FILTERS
     *******************************************************************************/
    tl2 = new TimelineMax({
        paused: true,
        reversed: true
    });
    menu_back_filter = "#content-menu-filter";
    menu_content_filter = "#content-menu-filter ul";

    tl2.staggerTo(menu_back_filter, 0.9, {
        right: '0%',
        ease: Power4.easeOut
    })
    tl2.staggerTo(menu_content_filter, 0.9, {
        left: '0%',
        ease: Power4.easeOut
    }, 0.1, '-=1.2');

    $('#hamburger-filter').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass("open");
        tl2.reversed() ? tl2.play() : tl2.reverse();
    });


    /*******************************************************************************
     PARALLAX
     *******************************************************************************/
    skrollr.init({
        mobileCheck: function() {
            return false;
        }
    });


    /*******************************************************************************
     SLIDER
     *******************************************************************************/

    // $(".swiper-container-herox").each(function(index, element) {
    //     var $this = $(this);
    //     if ($(".swiper-wrapper .swiper-slide").length == 1) {
    //         $('.swiper-pagination').addClass("disabled");
    //     }
    //     var swiper = new Swiper("#slider-hero", {
    //         paginationClickable: true,
    //         preventClicks: false,
    //         keyboardControl: true,
    //         resistanceRatio: 0,
    //         preventClicksPropagation: false,
    //         speed: 500,
    //         parallax: true,
    //         pagination: ".swiper-pagination-hero",
    //         nextButton: ".swiper-button-next-hero",
    //         prevButton: ".swiper-button-prev-hero"
    //     });
    // });





    // FORMULARIO SALESFORCE


    $('.documento').on('change', function() {
        // Obtener el valor seleccionado
        var selectedValue = $(this).val();
        console.log('Tipo de documento seleccionado:', selectedValue);

        // Cambiar dinámicamente el atributo maxlength del input según la opción seleccionada
        switch (selectedValue) {
            case 'DNI':
                $('.numero').attr('maxlength', 8);
                $('.numero').attr('minlength', 8);
                $('.numero').removeClass('disabled');
                $('.numero').addClass('enabled');
                break;
            case 'Carnet de Extranjería':
                $('.numero').attr('maxlength', 12);
                $('.numero').attr('minlength', 12);
                $('.numero').removeClass('disabled');
                $('.numero').addClass('enabled');
                break;
            case 'RUC':
                $('.numero').attr('maxlength', 11);
                $('.numero').attr('minlength', 11);
                $('.numero').removeClass('disabled');
                $('.numero').addClass('enabled');
                break;
            default:
                // Si no hay selección, restaurar el valor predeterminado
                $('.numero').attr('maxlength', 20);
                $('.numero').attr('minlength', 20);
                $('.numero').val(''); // Vaciar el campo
                $('.numero').removeClass('enabled');
                $('.numero').addClass('disabled');
        }

        // Quitar la propiedad 'required' si el valor seleccionado es vacío
        if (selectedValue === '') {
            $('.numero').prop('required', false);
        }
        else {
            // Establecer la propiedad 'required' si el valor seleccionado no es vacío
            $('.numero').prop('required', true);
        }
    });

    // Obtener el elemento con el ID 'phone'
    try {
        var phoneInput = document.getElementById('phone');

        phoneInput.addEventListener('input', function(event) {
            // Obtener el valor actual del campo de teléfono
            var phoneNumber = event.target.value;

            // Eliminar caracteres no numéricos usando una expresión regular
            phoneNumber = phoneNumber.replace(/[^\d]/g, '');

            // Actualizar el valor del campo de teléfono
            event.target.value = phoneNumber;
        });
    }
    catch (error) {
       // console.log("Ocurrió un error:", error.message);
    }


});
